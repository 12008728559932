.banner-slider {
    .swiper {
        display: flex;
        justify-content: center;
        
        width: 100%;
        height: 100%;
        border-radius: 40px;
    }
    .swiper-button-prev,
.swiper-button-next {
  color: black; /* Change button color */
  font-size: 20px; /* Increase button size */
}
    
    .swiper-slide {
        text-align: center;
        background: #ffffff00;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
    
    .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
    }

    @media screen and (min-width: 767px) {
        .swiper-pagination {
            bottom: -5px !important;
            position: relative;
        }
    }

    .swiper-pagination-bullet-active {
        width: 28px !important;
        background-color: #CDCDCD !important;
        border-radius: 20px;
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background-color: #8A8989 !important;
    }
   
   


}
.review-carousel{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.review-carousel {
    .swiper-slide {
            width:250px !important;
            height: 450px !important;
            overflow: hidden;
         
      

        @media screen and (max-width: 480px) {
            width: 80% !important;
            height:500px !important;
           
           
          }
    }
    
   


    .swiper-slide video {
    
        width: 90%;
        height:90%;
        object-fit:cover;
        background-color:#0000004f;
        border-radius: 15px;
        
      
    }

  

}


.success-right {
    &::after {
        content: "";
        position: absolute;
        min-width: 60px;
        width: 50%;
        height: 2px;
        background-color: #03977E;
        right: -28px;
        top: 10px;
    }
}

.success-left {
    &::before {
        content: "";
        position: absolute;
        min-width: 60px;
        width: 50%;
        height: 2px;
        background-color: #03977E;
        left: -28px;
        top: 10px;
    }
}

.pending-right {
    &::after {
        content: "";
        position: absolute;
        min-width: 60px;
        width: 50%;
        height: 2px;
        background-color: #DFDFDF;
        right: -28px;
        top: 10px;
    }
}

.pending-left {
    &::before {
        content: "";
        position: absolute;
        min-width: 60px;
        width: 50%;
        height: 2px;
        background-color: #DFDFDF;
        left: -28px;
        top: 10px;
    }
}
.main-header {
    @apply flex justify-between items-baseline;
    border-bottom: 1px solid #0000000F;
    background-color: #FAFAFA;
   
}

.header-links li {
    color:#000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: center;
    // text-underline-position: from-font;
    // text-decoration-skip-ink: none;


}

// ---
.menu-icon {
    display: none;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    transition: all 0.3s cubic-bezier(0.44, 0, 1, 1) !important;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 60px;
}

.nav-elements ul a {
    font-size: 20px;
    font-weight: 900;
    color: #2f234f;
    text-decoration: none;
}
.desktop-nav ul a{
    font-size: 20px;
    font-weight: 900;
    color: #2f234f;
    text-decoration: none;
}

.nav-elements ul a.active {
    font-family:'childhood';
    color: #574c4c;
    font-weight: 900;
    position: relative;
    color: inherit !important;   /* Inherits text color */
    background: transparent !important;  /* Removes background */
    box-shadow: none !important;  /* Removes any shadow */
    border: none !important;  /* Removes borders */
    outline: none !important;  /* Removes focus outline */
    text-decoration: none !important;  /* Removes underline */
    padding: 0 !important;  /* Resets padding */
    margin: 0 !important;  /* Resets margin */
}

.nav-elements ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}

@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 768px) {
    .desktop-nav, .desktop-main-icon {
        display: none;
    }
    .menu-icon {
        display: flex;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
        font-size: 32px;
        // ul {
        //     a {
        //         font-size: 30px;
               
        //     }
        // }
    }

    .nav-elements.active {
       
        width: 100%;
        padding-left:30px;
      
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }
}

.track-order, .cart-info{
    width: 40px;
    height: 40px;
    background: #F4F5F6;
    border: 1px solid #EAECF0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
}
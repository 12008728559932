@import './common.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;


// @font-face {
//   font-family:'Daykids';
//   src: url('./assets/fonts/Daykids_font/Daykids.otf') format('opentype');
//   font-weight: bold; /* Adjust weight if needed */
//   font-style: normal;  /* Adjust style if needed */
// }
// @font-face {
//   font-family:'Daykids';
//   src: url('./assets/fonts/Daykids_font/Daykids.ttf') format('truetype');
//   font-weight: bold; /* Adjust weight if needed */
//   font-style: normal;  /* Adjust style if needed */
// }

@font-face {
  font-family:'childhood';
  src: url('./assets/fonts/childhood//childhood.otf') format('opentype');
  font-weight: bold; /* Adjust weight if needed */
  font-style: normal;  /* Adjust style if needed */
}

@font-face {
  font-family:'GeneralSans-Regular';
  src: url('./assets//fonts/Generalsans/GeneralSans-Regular.otf') format('opentype');
  font-style: normal;  /* Adjust style if needed */
}

@font-face {
  font-family:'Nunito-Regular';
  src: url('./assets//fonts/Nunito/Nunito-Regular.ttf ') format('truetype');
  font-style: normal;  
  /* Adjust style if needed */
}




* {
  
  font-family: 'Nunito-Regular';
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:active {
    color: inherit;
  }
}

body ,html{

  margin: 0 auto;
  padding: 0;
  min-height: 100vh;

  #root {
    background-color:#FAFAFA;
    min-height: 100vh;
  }

  background-color: #ffffff;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: 100%;
}

.main-heading {
  font-size: 36px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  color: #101828;
}

.main-heading-sub {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
  color: #101828;
}

.w-50 {
  width: 50%;
}

.wrapper-heading {
  margin-top: 32px;
}

.vertical-line {
  margin: 0 30px;
  border: 1px solid #EAECF0;
}

.small-heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #BD3434;
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
}

.form-field {
  width: 70%;
  // max-width: 420px;
}

.w-image {
  width: 30%;
  min-width: 250px;
  margin-right: 32px;
}

hr {
  margin: 32px 0;
  color: #EAECF0;
}

.dropdown-selector {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #fff;
  background-size: 10px;
}

.form-label {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #171718;
}

.dynamic-form-wrapper {
  margin-bottom: 32px;
}

.detail-page-btn-wrapper {
  margin-top: 24px;
}

.main-heading-sub-bold {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.details-left {
  width: 40%;
  margin-right: 32px;
}

.details-right {
  width: 60%;
}

.info-heading {
  font-size: 20px;
}

.preview-details-btn-actions {
  margin-top: 32px;

  .btn-secondary {
    margin-right: 16px;
  }
}

.order-detail-preview-box {
  padding: 15px;
  border: 1px solid #CDCDCD;
  background: #F1F1F1;
  border-radius: 8px;
}

.detail-preview-img {
  width: 100%;
  height: auto;
}

.align-item-end {
  align-items: end;
}

input:focus,
select:focus {
  outline: none;
  border: 1px solid #9FA4AC;
  box-shadow: 0px 0px 0px 3px #9FA4AC29;
}

.error-message {
  color: #EF3B42;
  font-size: 12px;
  margin-top: 5px;
}

.hover-img-bg {
  &:hover {
    filter: brightness(0.8);
  }
}

.MuiBackdrop-root {
  opacity: 0.5;
  z-index: 999;
}

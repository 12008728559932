$black: #000;

$white: #fff;
$white1: rgba(255, 255, 255, 0.3);
$white2: #fafaf8;
$white3: #f9fafb;
$white4: #f0f1f3;
$white5: #f8f9fa;
$white6: #eee;

$green1: #41dd8c;
$green2: #73e5aeb3;

$orange1: #2e2d2c;
$orange2: #2b2a29;

$pink1: #e54dc0;

$blue1: #1d1d72;
$blue2: #4b4bbc;
$blue3: #191970;
$blue4: #393988;
$blue5: #2b2b77;

$gray0: #f1f2f4;
$gray1: #7c7c7c;
$gray2: #96999c;
$gray3: #f3f3f3;
$gray4: #dfdfdf;
$gray5: #e5e5e5;
$gray6: #f1f2f4;
$gray7: rgba(124, 124, 124, 0.3);

$blue: #4b4bbc;

$red: #ff0000;
$red1: #ff4b55;
$red2: #fee9ea;
$red3: #fe4e57;

$xsmalFontSize: 10px;
$tabCountFontSize: 11px;
$smallFontSize: 12px;
$commonFontSize: 14px;
$mediumFontSize: 16px;
$largeFontSize: 20px;
$xlargeFontSize: 24px;
$mediumLargeFontSize: 26px;
$xxlargeFontSize: 28px;
$xxxlargeFontSize: 30px;
$xsmallHeaderFontSize: 32px;
$headerFontSize: 40px;
$smallHeaderFontSize: 156px;
$mediumHeaderFontSize: 160px;
$largeHeaderFontSize: 180px;

$thinFont: 300;
$normalFont: 400;
$mediumFont: 500;
$semiBoldFont: 600;
$boldFont: 700;

.show-loader {
    overflow: hidden;
    opacity: 0.8;
    padding-right: 17px;
}

.btn-primary {
    background-color: #F67552;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
    border: 1px solid #F67552;

    &:hover {
        background-color: #fff2ee;
        box-shadow: 0px 1px 2px 0px #1018280D;
        color: #F67552;
    }
}

.btn-secondary {

    background-color: #fff2ee;
    border: 1px solid #F67552;
    box-shadow: 0px 1px 2px 0px #1018280D;
    color: #F67552;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #F67552;
        color: white;
    }
}

.btn-link {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #BC2514;
    cursor: pointer;
}

.flex-end {
    justify-content: flex-end;
}

.preview-handler-wrapper {
    margin-left: 32px;
}

/* STRAT - App fonts urbanist */
@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-Thin.ttf) format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'urbanist';
    src: url(./assets/fonts/urbanist/Urbanist-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

/* END - App fonts */

.show-loader {
    overflow: hidden;
    opacity: 0.8;
    padding-right: 17px;
}
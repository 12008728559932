.layout-section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
}

// .upper-layout-section {
//     padding: 0 30px;
// }

.header-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}

li {
    &.active {
        a {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #000000;
            border-bottom: 1px solid;
        }
    }
}
/* Hide the default checkbox */
input[type="checkbox"] {
    display: none;
}

/* Create a custom checkbox container */
.checkbox-container {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    border: 2px solid #EF3B42;
}

/* Add the checkmark icon (hidden by default) */
.checkbox-container::after {
    content: url('../../assets/images/svg/tick.svg');
    color: white;
    font-size: 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked+.checkbox-container::after {
    display: block;
}

input[type="checkbox"]:checked+.checkbox-container {
    background-color: #EF3B42;
}

.coupan-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
}

.selected-coupan-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
}

.coupan-key {
    margin-top: 20px;
 
   
    font-weight: 700;
    border-style: dashed;
    border-width: 3px;
    padding: 10px;
    text-align: center;
    background-color: #03977e82;
    color: white;
}

// ---

.address-form {
    form {
        font-family: Arial, sans-serif;
        max-width: 500px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;
    }

    h2,
    h3 {
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
    }

    label {
        display: block;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
        color: #7E818C;
    }

    input,
    select {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        font-size: 14px;
    }

    button {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        // background-color: #007BFF;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    button:hover {
        background-color: #0056b3;
    }

    p {
        margin: -15px 0 15px;
        font-size: 12px;
        color: #EF3B42;
    }

    @media (max-width: 768px) {
        form {
            padding: 15px;
        }

        input,
        select,
        button {
         
            padding: 8px;
        }
    }

}
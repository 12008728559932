.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal-content {
   
        background: white;
    border-radius: 20px;
    position: relative;
}

.modal-header {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #070A0C;
}

textarea {
    background: #FFFFFF;
    border: 1px solid #A2ADB5;
    height: 95px;
    margin: 32px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #667085;
    padding: 10px 12px 10px 12px;
    border-radius: 6px;
    box-sizing: border-box;
}
.product-mobile-carousel {
    .swiper-slide {
        width: 306px !important;
        height: auto !important;
    }

    .swiper-pagination-bullet-active {
        width: 28px !important;
        background-color: #CDCDCD !important;
        border-radius: 20px;
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background-color: #8A8989 !important;
    }
}
@media (max-width: 998px) {
    .footer-info {
        flex-wrap: wrap;
        gap: 9px;
        flex-direction: column-reverse;
        margin-top: 12px;
    }
}

.footer-links li {
    color:black;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}


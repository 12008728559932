.otp-form {

    .info {
        margin-bottom: 10px;
    }

    .title {
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 800;
        letter-spacing: -0.025em;
    }

    .description {
        margin-top: 10px;
        font-size: 15px;
    }

    .form .inputs {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .form .inputs input {
        height: 10px;
        width: 20px;
        outline: none;
        text-align: center;
        font-size: 1.5em;
        border-radius: 0.3em;
        border: 1px solid rgba(119, 119, 119, 0.363);
        background-color: rgb(255 255 255 / 0.05);
    }

    @media (max-width: 640px) {
        .form .inputs {
            gap: 0.4em; /* Reduce spacing on small screens */
        }
        
        .form .inputs input {
            height: 2.2em;
            width: 1.8em;
            font-size: 1.2em;
            border-radius: 0.25em;
        }
    }
    
    /* 📏 Tablet Styles */
    @media (max-width: 768px) {
        .form .inputs input {
            height: 2.3em;
            width: 2em;
            font-size: 1.3em;
        }
    }
    
    /* 💻 Large Screen Optimization */
    @media (min-width: 1024px) {
        .form .inputs input {
            height: 2.1em;
            width: 2.1em;
            font-size: 1.6em;
        }
    }

    .resend {
        color: rgba(62, 62, 62, 0.718);
        margin-top: 10px;
        font-size: 15px;
        text-align: left;
    }

    .resend-action {
        text-decoration: none;
        cursor: pointer;
        margin-left: 6px;
        font-weight: 600;
    }

    .resend-action:hover {
        text-decoration: underline #2b8af7;
    }

    .validate {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        text-decoration: none;
        background-color: #03977e;
        padding: 20px 170px;
        margin: 8px 0 0 0;
        font-size: 13px;
        font-weight: 600;
        border-radius: 30px;
        transition: 0.3s ease;
    }


    .close {
        margin-left: -10px;
        margin-bottom: 15px;
        height: 30px;
        width: 30px;
        display: grid;
        place-items: center;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
        transition: 0.3s ease;
    }
}